<template>
  <p>
    {{isWeiXin ? "正在进入..." :"请使用微信扫码访问。"}}
  </p>
<!--  <div style="width: 100vw">-->
<!--    <input v-model="appId">-->
<!--    <button @click="goRedirect">跳转</button>-->
<!--    <p>uid</p>-->
<!--    <p class="p-content">-->
<!--      {{-->

<!--        getCookie('uid')-->
<!--      }}-->
<!--    </p>-->
<!--    <p>sid</p>-->
<!--    <p class="p-content">-->
<!--      {{-->
<!--        getCookie('sid')-->
<!--      }}-->
<!--    </p>-->
<!--    <p>config:</p>-->
<!--    <p class="p-content">-->
<!--      {{-->
<!--        JSON.stringify($config)-->
<!--      }}-->

<!--    </p>-->

<!--    <p>wxRequestUrl:</p>-->
<!--    <p class="p-content">-->
<!--      {{-->
<!--        wxRequestUrl-->
<!--      }}-->
<!--    </p>-->

<!--    <p>redirect_url:</p>-->
<!--    <p class="p-content">-->
<!--      {{-->
<!--        redirect_url-->
<!--      }}-->
<!--    </p>-->

<!--    <p class="p-content">-->
<!--      {{-->
<!--        tip-->
<!--      }}-->
<!--    </p>-->
<!--  </div>-->

</template>


<script>
import {getCookie, isWeiXin as judgeWeixin} from "../../util";
import axios,{getMyInfo} from "../../api/api";
import config from "../../config";
import {ElMessage} from "element-plus";
//这个页面专门用于微信浏览器环境
export default {
  name: "WeChatAuth",
  data(){
    return {
      appId:'wx51e861d422efa2bc',
      $config:config,
      wxRequestUrl:'',
      frontEndUrl:'',
      tip:'',
      redirect_url:this.$route.query.to || this.$route.query.t,
      getCookie:getCookie
    }
  },
  computed:{
    isWeiXin(){
      return judgeWeixin();
    }
  },
  methods:{
    goRedirect(){
      let to = this.$route.query.to || this.$route.query.t;
      //有to的话,就让用户调接口
      if(to){
        getMyInfo().then(res=>{
          if(res.data?.code == 401 || res.data?.code == 402){

            //没有用户的信息,去拉微信->uranus->vue的mobileLogin页面->checkGoRedirectUrl方法调用该接口->接口重定向->后端里面指定的vue页面
            window.location.href = this.wxRequestUrl;
            localStorage.setItem("redirect_url",to);
          } else{
            window.location.href = to;
          }
        })

        // axios.get(to).then(res=>{
        //   if(res.data?.code == 402 || res.data?.code == 401){
        //     //没有用户的信息,去拉微信->uranus->vue的mobileLogin页面->checkGoRedirectUrl方法调用该接口->接口返回地址->vue拿到地址进行跳转
        //     window.location.href = this.wxRequestUrl;
        //   }else if(res.data.code == 200 || res.data.code == 0){
        //     //vue拿到地址进行跳转
        //     window.location.href = res.data.data;
        //   }else{
        //     ElMessage.warning(
        //         `错误 res: ${JSON.stringify(res)} to : ${to}`
        //   );
        //   }
        // })
      }
    }
  },
  created() {
    //注意#号换成@号
                                            //转到手机登录页面
    this.frontEndUrl = window.encodeURIComponent(`${config.baseUrl}clm/@/mobileLogin?origin=metago`.replace(/&/g,','));
    const uranusUrl = window.encodeURIComponent(config.baseUrl +'login');
    this.wxRequestUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${uranusUrl}&response_type=code&scope=snsapi_userinfo&state=${this.frontEndUrl}`
    this.isWeiXin && this.goRedirect();
  },
  mounted() {
  }
}
</script>

<style scoped>

.p-content{
    padding: 20px;
    width: 300px;
    word-break: break-all;
    white-space: pre-wrap;
  }
</style>
